:root {
	--color-primary: #335AA6;
	--color-primary-light: #89ABD9;
	--color-primary-dark: #18358C;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;
	--color-grey-dark-1: #404040;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--color-red: #A52631;
	--color-green: #207245;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Roboto', sans-serif;
}

/* ANIMATION */
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

/* APP */
.app {
	height: 100vh;
	width: 100%;
	display: flex;
}
.loader {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary-dark);
}
.loader i {
	-webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
	animation:spin 4s linear infinite;
	font-size: 4.5rem;
	color: #fff;
}
header {
	width: 100%;
    background-color: #fff;
    padding: 0 2rem;
    border-bottom: var(--line);
    display: flex;
    align-items: center;
    height: 6rem;
}
header .logo {
	text-align: center;
	font-size: 1.4rem;
	font-weight: 500;
	color: var(--color-primary);
	text-transform: uppercase;
}
header button {
	margin-left: auto;
	display: flex;
	border: none;
	background-color: transparent;
	color: rgba(165, 38, 49, .8);
	cursor: pointer;
	outline: none;
}
.app main {
	flex: 1;
	overflow-y: auto;
}
.app .sidenav {
	overflow-y: auto;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	transition: all .5s;
	width: auto;
}
.sidenav img {
	width: 4rem;
	margin: 1rem auto;
}
.app .sidenav ul {
	display: flex;
	flex-direction: column;
	list-style: none;
}
.app .sidenav ul a {
	padding: 1.3rem;
	color: var(--color-primary);
	font-size: 1.4rem;
	text-decoration: none;
	text-transform: uppercase;
	display: flex;
	align-items: center;
}
.app .sidenav ul a:hover,
.app .sidenav ul a.active {
	background-color: var(--color-primary-dark);
	color: #fff;
}
.app .sidenav ul a span {
	margin-left: 1rem;
}
.app .sidenav ul.close a span {
	display: none;
}
.app__container {
  position: relative;
}
.app__container > button {
	position: absolute;
	top: 3rem;
	left: 0;
	border: none;
	background-color: var(--color-primary);
	padding: .25rem;
	color: #fff;
	cursor: pointer;
	outline: none;
	border-top-right-radius: .5rem;
	border-bottom-right-radius: .5rem;
	opacity: .6;
	transition: all .5s;
	display: flex;
}
.app__container > button:hover {
	opacity: 1;
}
.in-nav {
	display: flex;
	width: 100%;
}
.in-nav ul {
	display: flex;
	width: 100%;
}
.in-nav ul a {
	flex: 1;
	padding: 1.5rem;
	background-color: var(--color-primary-light);
	text-align: center;
	color: #fff;
	font-size: 1.2rem;
	text-decoration: none;
	text-transform: uppercase;
	transition: all .5s;
	display: flex;
	justify-content: center;
	align-items: center;
}
.in-nav ul a:hover,
.in-nav ul a.active {
	background-color: var(--color-primary-dark);
}
.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: 1.4rem;
	text-transform: uppercase;
	color: var(--color-primary);
}
.input-container input,
.input-container textarea {
	padding: 1rem;
	outline: none;
	border: none;
	background-color: rgba(255,255,255,.8);
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.06);
	margin-top: 1rem;
	width: 100%;
	margin-top: auto
}
.input-container select {
	padding: 1rem;
	outline: none;
	border: none;
	background-color: rgba(255,255,255,.8);
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.06);
	margin-top: 1rem;
	width: 100%;
	height: 3.5rem;
	margin-top: auto;
	border-radius: 0px;
	display: flex;
}
.input-container button {
	padding: 1rem;
	border: none;
	background-color: var(--color-primary);
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
	margin-top: auto;
}
table {
	width: 100%;
	border-collapse: collapse;
	font-size: 1.2rem;
}
table th {
	background-color: var(--color-primary);;
	padding: 1rem;
	text-transform: uppercase;
	color: #fff;
	font-weight: 600;
}
table td {
	color: var(--color-grey-dark-1);
	padding: 1rem;
}
table tr:nth-of-type(even) td {
	background-color: rgba(255,255,255,.6);
}
table tr.red td {
	background-color: rgba(165, 38, 49, .3);
}
table td a {
	color: var(--color-primary);
	text-decoration: none;
}
table td input,
table td select {
	width: 100%;
	padding: .5rem;
	border: none;
	box-shadow: .5px .5px 1px rgba(0, 0, 0, 0.1);
	background-color: rgba(255,255,255,.3);
	outline: none;
}
table td.center {
	text-align: center;
}
table td.center button {
	margin: 0 auto;
}
table td button {
	border: none;
	background-color: transparent;
	display: flex;
	cursor: pointer;
	color: var(--color-primary-dark);
	outline: none;
}
table th button {
	padding: 1rem;
	cursor: pointer;
	outline: none;
	background-color: var(--color-primary-light);
	border: none;
	color: #fff;
}
.date-picker {
	display: flex;
	align-items: center;
	justify-content: center;
}
.date-picker button {
	border: none;
	width: 5rem;
	height: 5rem;
	background-color: #fff;
	color: var(--color-primary);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
}
.date-picker span {
	margin: 0 2.5rem;
}
.seeker {
	display: flex;
	background-color: var(--color-grey-light-2);
	margin-bottom: 2.5rem;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
	border-radius: 2.5rem;
	overflow: hidden;
}
.seeker span {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: .5rem 1rem;
}
.seeker input {
	outline: none;
	flex: 1;
	padding: 0 1rem;
	border: none;
	background-color: transparent;
}

/* LOG-IN */
.log-in {
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-primary);
}
.log-in form {
	background-color: rgba(255,255,255,.8);
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 40rem;
	box-shadow: var(--shadow-light);
}
.log-in form h1 {
	font-size: 2.5rem;
	color: var(--color-primary);
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
}
.log-in form a {
	margin-left: auto;
	margin-top: 1rem;
	font-size: 1.4rem;
	color: var(--color-primary);
	text-decoration: none;
	text-transform: uppercase;
}

/* SALES */
.sales-customers {
	padding: 2.5rem;
}
.sales-customers .filter {
	display: flex;
}
.sales-customers .filter button {
	padding: 1rem;
	border: 1px solid var(--color-primary);
	background-color: transparent;
	margin-right: 1rem;
	color: var(--color-primary);
	text-transform: uppercase;
	cursor: pointer;
	margin-bottom: 1rem;
	outline: none;
}
.sales-customers .filter button:hover,
.sales-customers .filter button.active {
	background-color: var(--color-primary);
	color: #fff;
}
.sales-single-customer {
	padding: 2.5rem;
}
.sales-single-customer section {
	display: flex;
	margin-bottom: 2.5rem;
}
.sales-single-customer section table {
	flex: 0 0 35%;
	margin-right: 2.5rem;
}
.sales-single-customer-directory {
	flex: 1;
}
.sales-single-customer-directory form {
	display: flex;
	flex-wrap: wrap;
}
.sales-single-customer-directory form .input-container {
	flex: 0 0 50%;
}
.sales-single-customer-seller {
	width: 100%;
	padding: 4.5rem 0;
}
.sales-single-customer-seller h3 {
	font-size: 2rem;
	color: var(--color-primary);
	text-align: center;
	text-transform: uppercase;
	padding: 1rem;
	border-bottom: 2px solid var(--color-primary);
	margin-bottom: 1.5rem;
}
.sales-single-customer-seller .container {
	display: flex;
}
.sales-single-customer-seller .container form {
	flex: 1;
	margin-right: 2.5rem;
}
.sales-single-customer-seller .container ul {
	flex: 1;
	list-style: none;
	font-size: 1.4rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: var(--color-grey-dark-1);
}
.sales-single-customer-price-list {
	padding: 4.5rem 0;
	width: 100%;
}
.sales-single-customer-price-list h3 {
	font-size: 2rem;
	color: var(--color-primary);
	text-align: center;
	text-transform: uppercase;
	padding: 1rem;
	border-bottom: 2px solid var(--color-primary);
	margin-bottom: 1.5rem;
}
.sales-single-customer-price-list .container {
	display: flex;
}
.sales-single-customer-price-list .container form {
	flex: 0 0 35%;
	margin-right: 2.5rem;
}
.sales-single-customer-price-list .container table {
	flex: 1;
}
.sales-price-list {
	padding: 2.5rem;
}
.sales-price-list h1 {
	font-size: 2rem;
	color: var(--color-primary);
}
.sales-price-list p {
	padding: 1rem;
	font-size: 1.4rem;
	color: var(--color-grey-dark-3);
}
.sales-price-list form {
	display: flex;
	flex-wrap: wrap;
}
.sales-price-list form .input-container {
	flex: 1 0 30%;
}
.sales-customers-form {
	padding: 2.5rem;
}
.sales-customers-form h1 {
	font-size: 2rem;
	color: var(--color-primary);
	padding: 1rem;
	border-bottom: 2px solid var(--color-primary);
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 2rem;
}
.sales-customers-form form {
	display: flex;
	flex-wrap: wrap;
}
.sales-customers-form form .input-container {
	flex: 0 0 25%;
}
.sales-customers-form form h4 {
	flex: 0 0 100%;
	font-size: 1.4rem;
	margin-top: 1rem;
	color: var(--color-primary);
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary);
	text-transform: uppercase;
}
.sales-memo {
	padding: 2.5rem;
}
.sales-memo form {
	display: flex;
	margin-bottom: 2.5rem;
	padding: 2rem;
	background-color: rgba(255,255,255,.5);
}
.sales-memo form .input-container {
	flex: 1;
}
.sales-orders {
	padding: 2.5rem;
}
.sales-orders-form {
	padding: 2.5rem;
}
.sales-orders-form .container {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 8rem;
	position: relative;
}
.sales-orders-form .container .input-container {
	flex: 1 0 30%;
}
.sales-orders-form .container form {
	display: flex;
	flex: 0 0 100%;
	margin-top: 2rem;
	padding: 1rem;
	background-color: rgba(255, 255, 255, .5);
}
.sales-orders-form .container form .input-container {
	flex: 1;
}
.sales-orders-form .container .submit {
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 1.5rem 2rem;
	background-color: var(--color-green);
	color: #fff;
	text-transform: uppercase;
	border: none;
	cursor: pointer;
	outline: none;
}
.sales-invoicing {
	padding: 2.5rem;
}
.sales-invoicing-form {
	padding: 2.5rem;
}
.sales-invoicing-form form {
	display: flex;
	flex-wrap: wrap;
}
.sales-invoicing-form form .input-container {
	flex: 0 0 50%;
}
.sales-single-order {
	padding: 2.5rem;
}
.sales-single-order h1 {
	font-size: 2.5rem;
	color: var(--color-grey-dark-1);
}
.sales-single-order h2 {
	font-size: 2rem;
	color: var(--color-primary);
	padding: 1rem;
}
.sales-single-order ul {
	padding: 1rem;
	list-style: none;
	font-size: 1.4rem;
	display: flex;
	flex-wrap: wrap;
}
.sales-single-order ul li {
	flex: 0 0 50%;
	padding: 1rem;
	color: var(--color-grey-dark-1);
}

/* PRODUCTION */
.production-form {
	padding: 2.5rem;
}
.production-form form {
	display: flex
}
.production-form form .input-container {
	flex: 1;
}
.production-moves {
	padding: 2rem;
}

/* CONFIGURATION */
.configuration-cfdis {
	padding: 2.5rem;
	display: flex;
}
.configuration-cfdis form {
	flex: 0 0 30%;
	margin-right: 2.5rem;
}
.configuration-cfdis table {
	flex: 1;
}
.configuration-sat-payway {
	padding: 2.5rem;
	display: flex;
}
.configuration-sat-payway form {
	flex: 0 0 30%;
	margin-right: 2.5rem;
}
.configuration-sat-payway table {
	flex: 1;
}
.configuration-brands {
	padding: 2.5rem;
	display: flex;
}
.configuration-brands form {
	flex: 0 0 30%;
	margin-right: 2.5rem;
}
.configuration-brands .container {
	flex: 1;
}
.configuration-users {
	padding: 2.5rem;
}
.configuration-products {
	padding: 2.5rem;
	display: flex;
	width: 100%;
}
.configuration-products-form {
	flex: 1;
	margin-right: 2rem;
}
.configuration-products .container {
	flex: 2;
}
.configuration-single-product {
	padding: 2.5rem;
}
.configuration-single-product .details {
	display: flex;
}
.configuration-single-product .details table {
	flex: 0 0 50%;
}
.configuration-expenses {
	display: flex;
	padding: 2.5rem;
}
.configuration-expenses form {
	flex: 0 0 25%;
	margin-right: 2.5rem;
}
.configuration-expenses table {
	flex: 1;
}
.configuration-passwords {
	padding: 2.5rem;
}
.configuration-passwords form {
	display: flex;
	padding: 1rem;
	background-color: #fff;
}
.configuration-passwords form .input-container {
	flex: 1;
}
.configuration-passwords table {
	margin-top: 2.5rem;
}
.configuration-receipes {
	padding: 2.5rem;
}
.configuration-single-user {
	padding: 2.5rem;
}
.configuration-single-user h1 {
	font-size: 2rem;
	color: var(--color-primary);
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary);
}
.configuration-single-user .perms__container {
	display: flex;
	flex-wrap: wrap;
}
.configuration-single-user .perms__container .input-container {
	flex: 0 0 25%;
}
/* IMPORTER */
.importer {
	padding: 2.5rem;
}

/* WAREHOUSE */
.warehouse {
	padding: 2.5rem;
}
.warehouse form {
	display: flex;
	padding: 2rem;
	background-color: rgba(255, 255, 255, .5);
	margin-bottom: 2.5rem;
}
.warehouse form .input-container {
	flex: 1;
}

/* PURCHASES */
.purchases-providers {
	padding: 2.5rem;
}
.purchases-providers-form {
	padding: 2.5rem;
}
.purchases-quote {
	padding: 2.5rem;
}
.purchases-quote-form {
	padding: 2.5rem;
}
.purchases-quote-form .container {
	display: flex;
	flex-direction: column;
}
.purchases-quote-form .content {
	display: flex;
	margin-top: 2.5rem;
}
.purchases-quote-form .content form {
	flex: 1;
}
.purchases-quote-form .content table {
	flex: 1;
}
.purchases-quote-form .container > button {
	padding: 1.5rem 2rem;
	border: none;
	background-color: var(--color-green);
	color: #fff;
	cursor: pointer;
	outline: none;
	margin-top: 2.5rem;
	width: 100%;
}
.purchases-orders {
	padding: 2.5rem;
}
.purchases-orders-form {
	padding: 2.5rem;
}
.purchases-single-quote {
	padding: 2.5rem;
	display: flex;
}
.purchases-single-quote table:first-of-type {
	margin-right: 2.5rem;
}
.purchases-single-quote table {
	flex: 1;
}

/* ACCOUNTING */
.accounting-purchases {
	padding: 2.5rem;
}
.accounting-incomes {
	padding: 2.5rem;
}
.accounting-debts {
	padding: 2.5rem;
}
.accounting-receivable {
	padding: 2.5rem;
}
.accounting-balance {
	padding: 2.5rem;
}
.accounting-balance__filter {
	display: flex;
	list-style: none;
	margin-bottom: 2rem;
}
.accounting-balance__filter li {
	margin-right: 2.5rem;
	padding: 1.5rem 2rem;
	border: 1px solid var(--color-primary);
	color: var(--color-primary);
	text-transform: uppercase;
	background-color: transparent;
	cursor: pointer;
	outline: none;
	font-size: 1.2rem;
}
.accounting-balance__filter li.active,
.accounting-balance__filter li:hover {
	background-color: var(--color-primary);
	color: #fff;
}
.accounting-incomes-form {
	padding: 2.5rem;
}
.accounting-incomes-form .container {
	display: flex;
}
.accounting-incomes-form .container .details {
	flex: 1;
}
.accounting-incomes-form .container table {
	flex: 2;
}

/* HUMAN-RESOURCES */
.human-resources-employees {
	padding: 2.5rem;
}
.human-resources-employees form {
	display: flex;
}
.human-resources-employees form .input-container {
	flex: 1;
}
.human-resources-employees table {
	margin-top: 2.5rem;
}
.human-resources-roster {
	padding: 2.5rem;
}
.human-resources-roster h1 {
	font-size: 2rem;
	color: var(--color-primary);
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary);
	margin-bottom: 2rem;
}
.human-resources-roster__form {
	display: flex;
}
.human-resources-roster__form .input-container {
	flex: 1;
}
.human-resources-roster table {
	margin-top: 4.5rem;
}

/* CALENDAR */
.calendar {
	padding: 2.5rem;
}
.calendar form {
	display: flex;
}
.calendar form .input-container {
	flex: 1;
}

/* PROMO */
.promo-main {
	padding: 2rem;
}
.promo-main .filter ul {
	display: flex;
	list-style: none;
	margin: 2rem 0;
	padding: 1rem;
	background-color: rgba(255,255,255,.6);
}
.promo-main .filter ul li {
	flex: 1;
}
.promo-main > form {
	display: flex;
	padding: 2rem;
	background-color: rgba(255,255,255,.6);
}
.promo-main table form {
	display: flex;
}
.promo-list {
	padding: 2.5rem;
}